<div class="row">
	<mat-form-field class="col s12 m6 l6">
		<mat-label>{{'t_menu' | translate}}: </mat-label>
		<mat-select [ngModel]="(measService.detailSearch | async).menuId"
			(ngModelChange)="measService.detailSearch.value.menuId = $event" [compareWith]="compareFN"
			[ngModelOptions]="{standalone: true}">
			<mat-option *ngFor="let id of measService.menu | async | orderByMenu" [value]="id.menuId">
				{{id.languageId | translate}}
			</mat-option>
		</mat-select>
	</mat-form-field>

	<div class="col s12 m6 l3">
		<button class="icon" (click)="clearAll()">
			<mat-icon>cleaning_services</mat-icon> {{'t_clear_filters' | translate}}
		</button>
	</div>

	@if(authService.userInfo().userData; as user) {
		@if(!user.isPrivate) {
			<div class="col s12 m6 l3">
				<button class="icon" (click)="addFilterEvent.emit(true)">
					<mat-icon>add</mat-icon> {{'t_more_filters' | translate}}
				</button>
			</div>
		}
	}
</div>

<div class="row">
	<app-filter [addFilterEvent]="addFilterEvent" [detailCompare]="(measService.detailSearch | async).details"
		class="s12"></app-filter>
</div>

<div class="row">
	<mat-form-field appearance="fill" class="col s12 m4">
		<mat-label>{{'t_startdate' | translate}}: </mat-label>
		<input matInput [matDatepicker]="startpicker" [value]="(measService.detailSearch | async).startDate"
			(dateChange)="startdatechange($event)">
		<mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
		<mat-datepicker #startpicker></mat-datepicker>
	</mat-form-field>

	<mat-form-field appearance="fill" class="col s12 m4">
		<mat-label>{{'t_enddate' | translate}}: </mat-label>
		<input matInput [matDatepicker]="endpicker" [value]="(measService.detailSearch | async).endDate"
			(dateChange)="enddatechange($event)" />
		<mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
		<mat-datepicker #endpicker panelClass="datePickerEnd"></mat-datepicker>
	</mat-form-field>

	<mat-form-field class="col s12 m4">
		<mat-label>{{'t_serial' | translate}}:</mat-label>
		<input matInput [ngModel]="(measService.detailSearch | async).serial"
			(ngModelChange)="measService.detailSearch.value.serial = $event" [ngModelOptions]="{standalone: true}">
	</mat-form-field>
</div>

<div class="row">
	<div class="col s12 m12 l3 sendBtn">
		<button style="color: white;width: 100%;" class="icon-link" (click)="refresh()">
			<mat-icon [ngClass]="{'spin': (measService.isLoading | async)}">cached
			</mat-icon> {{'t_refresh' | translate}}
		</button>
	</div>

	<div class="s12 m12 l2" style="display: flex;align-items: center;">
		<!--  (change)="SlideChange()" -->
		<mat-checkbox #trashCan (change)="toggleArchive($event)" color="accent" [checked]="(measService.detailSearch | async).archived"> {{"t_trash_can" | translate}} </mat-checkbox>
	</div>

	@if(hasMap) {
		<div class="s12 m6 l4" style="display: flex;align-items: center;">
			<mat-slide-toggle #mapToggle color="accent" checked="true" (change)="toggleMap.emit(mapToggle.checked)">{{(mapToggle.checked ? 't_maps_view' : 't_cards_view') | translate}}</mat-slide-toggle>
		</div>
	}

	<div class="s12 m6 l-offset-2 l1" style="display: flex;align-items: center;justify-content: center;" *ngIf="!(measService.detailSearch | async).archived">
		<app-report-choose-multi-download [downloadCollectionReport]="downloadCollectionReport"></app-report-choose-multi-download>
		<!-- <a (click)="measService.multiExport()" class="icon-link">
			<mat-icon *ngIf="!(measService.downloading | async)">get_app</mat-icon>
			<mat-icon *ngIf="measService.downloading | async" [ngClass]="{'spin': (measService.downloading | async)}">
				cached</mat-icon>
		</a> -->
	</div>

	@if (trashCan.checked) {
		<div class="s12 centeringDiv" style="font-size: large; font-weight: bold; padding-left: 1rem;">
			{{'m_tash_can_info' | translate}}
		</div>
	}
</div>
